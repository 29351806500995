export const columns = [
  {
    title: "序号",
    dataIndex: "index",
    fixed: "left",
    width: 60,
    customRender: (value, item, index) => {
      return item.isTotal ? '合计' : (index + 1)
    },
  },
  {
    title: "产品编号",
    dataIndex: "material_number",
    fixed: "left",
    width: 140,
  },
  {
    title: "产品名称",
    dataIndex: "material_name",
    width: 300,
  },
  {
    title: "英文名称",
    dataIndex: "english_name",
    width: 300,
  },
  {
    title: "数量",
    dataIndex: "total_quantity",
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: "total_quantity" },
  },
  {
    title: "单位",
    dataIndex: "unit",
    width: 100,
    ellipsis: true,
  },
  {
    title: "批次编号",
    dataIndex: "batch_number",
    width: 240,
    ellipsis: true,
    scopedSlots: { customRender: "batch_number" },
  },
  {
    title: "总重量(吨)",
    dataIndex: "weight",
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: "weight" },
  },
  {
    title: "总体积(立方)",
    dataIndex: "volume",
    width: 100,
    ellipsis: true,
    scopedSlots: { customRender: "volume" },
  },
  {
    title: "包装规格",
    dataIndex: "spec",
    width: 100,
    ellipsis: true,
  },
  {
    title: "备注",
    dataIndex: "remark",
    width: 240,
    ellipsis: true,
    scopedSlots: { customRender: "remark" },
  },
  {
    title: "操作",
    dataIndex: "action",
    fixed: "right",
    width: 100,
    scopedSlots: { customRender: "action" },
  },
];
